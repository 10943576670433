
export const CardData = [
    {
        id: 1,
        title: "AI Assist",
        desc: "Create and Automate your customer communications and access the limitless power of AI right inside tawk.to",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/ai-assistant.svg",
        rate:"Free or Upgrade"
    },
    {
        id: 2,
        title: "Remove Branding",
        desc: "Truly customize the tawk.to widget and email and create a more professional messaging experience for your visitors by removing or replacing the tawk.to brand with your own.",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/remove-branding.svg",
        rate:"$19.00 / month"

    },

    {
        id: 3,
        title: "Hire Live Agents",
        desc: "Professionally trained agents are ready to answer chats on your behalf 24x7 from as low as $1/hr .",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/hire-live-agents.svg",
        rate:"$1.00 / hr"


    },
    {
        id: 4,
        title: "Virtual Assistant service by tawk.to",
        desc: "Create and Automate your customer communications and access the limitless power of AI right inside tawk.to",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/hire-virtual-assistants.svg",
        rate:"$7.00 / hr"

    },
    {
        id: 5,
        title: "Video + Voice + Screensharing",
        desc: "Delegate repetitive tasks and focus on what matters with the help of a skilled professional fully dedicated to your business.",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/remove-branding.svg",
        rate:"$29.00 / month"


    },

    {
        id: 6,
        title: "Activate Phone Number",
        desc: "You can now send and received SMS and Phone calls from your tawk dashboard!",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/activate-phone-number.svg",
        rate:"$17.00 / month"


    },

    {
        id: 7,
        title: "Translation",
        desc: "Automatically translate chats in real time & open your business to the world.",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/auto-translation.svg",
        rate:"$19.00 / month"

    },
    {
        id: 8,
        title: "Remove Cart",
        desc: "Turn any page with the widget in to a full fledge shopping cart.",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/shopping-cart.svg",
        rate:"$22.00 / month"


    },

    {
        id: 9,
        title: "In-Chat Payments",
        desc: "Accept payments from your visitors right in the chat window.",
        image: "https://dashboard.tawk.to/_s/assets/images/admin/addon/preview/in-chat-payments.svg",
        rate:"$29.00 / month"


    },

]
