import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Overview from '../Webview/Overview/Overview'
import ChatWidget from '../Webview/Channels/ChatWidget'
import Page from '../Webview/Channels/Page'
import Knowledgebase from '../Webview/Channels/Knowledgebase'
import Table from '../ReuseableCompo/Table/Table'
import Shortcuts from '../Webview/Settings/Shortcuts'
import Mailnotification from '../Webview/Settings/Mailnotification'
import Webhooks from '../Webview/Settings/WebHooks'
import Tabs from '../Webview/Settings/Tabs'
import AddOnss from '../Webview/AddOns/AddOnss'
import PurchasesInvoices from '../Webview/Billing/PurchasesInvoices'
import RemoveBranding from '../Webview/AddOns/RemoveBranding'
import HireVirtualAs from '../Webview/AddOns/HireVirtualAs'

function Systemnavigation() {
    return (

        <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/channels/chat-widget" element={<ChatWidget />} />
            <Route path="/channels/page" element={<Page />} />
            <Route path="/channels/knowledge-base" element={<Knowledgebase />} />
            <Route path="/users/members" element={<Table heading="PROPERTY MEMBERS" />} />
            <Route path="/users/departments" element={<Table heading="DEPARTMENTS" />} />
            <Route path="/users/alerts" element={<Table heading="AGENT ALERTS" />} />
            <Route path="/users/ban-list" element={<Table heading="BAN LIST" />} />
            <Route path="settings/shortcuts" element={<Shortcuts />} />
            <Route path="settings/triggers" element={<Table heading="TRIGGERS" />} />
            <Route path="/settings/mail" element={<Mailnotification />} />
            <Route path="/settings/tabs" element={<Tabs />} />
            <Route path="/settings/contact-attributes" element={<Table heading="CONTACT ATTRIBUTES" />} />
            <Route path="/settings/integrations" element={<Webhooks />} />
            <Route path="addon-store/ai-assistant" element={<AddOnss />} />
            <Route path="billing/purchases" element={<PurchasesInvoices />} />
            <Route path="/addon-store/whitelabel" element={<RemoveBranding title="Remove Branding" rate="$19.00 / month" subtitle="Truly customize the tawk.to widget and email and create a more professional messaging experience for your visitors by removing or replacing the tawk.to brand with your own." />} />
            <Route path="/addon-store/webrtc" element={<HireVirtualAs />} />
        </Routes>

    )
}

export default Systemnavigation          
