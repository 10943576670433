import React from 'react'
import Sidebar from '../Dashboard/Sidebar'
import Systemnavigation from './Systemnavigation'

function Panel() {
  return (
    <div className="p-5 flex gap-x-5">
      <Sidebar />
      <div className="mt-[50px] ml-[120px] lg:ml-[320px] w-[68vw] ">
        <Systemnavigation />
      </div>
    </div>
  )
}

export default Panel