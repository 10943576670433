import React from 'react'
import KnowledgeBase from "../../images/billing.png"

import { Link } from 'react-router-dom'
function PurchasesInvoices() {
    return (
        <React.Fragment>

            <div className='mt-1 shadow border border-gray-400 rounded h-[87vh] overflow-y-scroll'>
                <div className='sticky top-0 bg-white'>
                    <div className='text-lg font-bold text-gray-700 p-3'>Purchases and Invoices</div>
                    <hr className='border border-gray-300' />
                </div>
                <div className='flex justify-center items-center flex-col h-[87vh]'>
                    <img src={KnowledgeBase}  alt="hello" />
                    <div className='text-md mt-8'>You have not purchased any add-ons.</div>
                    <div className='text-md  mt-2'>Go to the <Link to="/addon-store/ai-assistant" > <span className='text-green-700 font-bold'>add-ons</span> </Link> section to view the list of available add-ons
                    </div>
                    <div className='flex gap-5'>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PurchasesInvoices