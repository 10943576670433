import React, { useState } from 'react'
import { DiscoverySettingsIcon } from '../../ReuseableCompo/icons'

function DiscoverSetting() {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const statusText = isChecked ? 'Enabled' : 'Disabled';
    return (
        <div>
            <div className='flex gap-2'>
                <div> {DiscoverySettingsIcon}</div>
                <div className='text-md font-bold text-gray-700'> <label> Discovery Settings</label></div>
            </div>

            <div className='card mt-2 flex flex-col bg-white p-4 h-60 shadow border border-gray-400 rounded'>
                <div>Discovery Listing</div>
                <div className='flex justify-between'>
                <div>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={isChecked}
                            onChange={handleChange}
                        />
                        <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}></div>
                    </label>
                </div>
                    <div>{statusText}</div>
                </div>
                <div className='mt-2'>
                    By enabling this feature this property will be listnd through the category listing pages.
                </div>
            </div>
        </div>
    )
}

export default DiscoverSetting