import React from 'react'
import Button from '../../ReuseableCompo/Button/Button'
import Carousel from '../../ReuseableCompo/Carousel'
import { FcOk } from 'react-icons/fc'
import { BiArrowBack } from 'react-icons/bi'
import { Link } from 'react-router-dom'

function RemoveBranding(props) {
    return (
        <React.Fragment>
            <div>
                <Link to="/addon-store/ai-assistant" className='hover:text-green-300'> <BiArrowBack /> </Link>
            </div>
            <div className='mt-3 shadow border border-gray-400 rounded h-[87vh] overflow-y-scroll  relative'>
                <div className='text-lg font-bold text-gray-700 p-3 h-[200px] bg-yellow-600 '></div>
                <div className=' p-3 h-[auto] bg-white border border-gray-400 rounded-md
                 absolute  w-[75%] top-[10%]  left-[50%] translate-x-[-50%]'>
                    <div className='flex justify-between'>
                        <div className='text-lg font-bold text-gray-700'> {props.title}</div>
                        <div className='text-sm font-bold text-gray-700'> {props.rate}</div>
                    </div>
                    <div className='flex justify-between mt-3'>
                        <div className='text-sm font-md opacity-80  text-gray-800'>{props.subtitle}</div>
                        <div className=''><Button btnName="Activate" className=" hidden lg:block p-2 bg-green-500 text-white" /></div>
                    </div>
                    <div className='mt-1'> <Carousel /> </div>
                    <div>
                        <div className='text-lg font-bold text-gray-700'>Overview</div>
                        <div className='text-sm font-md mt-2 opacity-80  text-gray-800'>ruly customize the tawk.to widget and email and create a more     professional messaging experience for your visitors by removing or replacing the tawk.to brand with your own.
                        </div>
                    </div>

                    <div className='points'>
                        <div className='flex items-center gap-3 mt-5'>
                            <div><FcOk /></div>
                            <div>
                                <p className='text-sm font-bold text-gray-800'>Remove/Customize branding on the widget</p>
                                <p className='text-sm font-md mt-2 opacity-100  text-gray-800'>— make your brand the one they see and remember.</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-3 mt-4'>
                            <div className=''><FcOk /></div>
                            <div>
                                <p className='text-sm font-bold text-gray-800'>Remove/Customize branding in ticketing emails and chat transcripts</p>
                                <p className='text-sm font-md mt-2 opacity-100  text-gray-800'>— build trust when you show up consistently every time.</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-3 mt-4'>
                            <div className=''><FcOk /></div>
                            <div>
                                <p className='text-sm font-bold text-gray-800'>Set up a custom support email address</p>
                                <p className='text-sm font-md mt-2 opacity-100  text-gray-800'>so every follow up and response now comes with your name on it — not ours!</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-3 mt-4'>
                            <div className=''><FcOk /></div>
                            <div>
                                <p className='text-sm font-bold text-gray-800'>Remove the tawk.to branding from the Knowledge Base and add your own domain</p>
                                <p className='text-sm font-md mt-2 opacity-100  text-gray-800'>- make it look like part of your website without hiring a developer to build it for you.</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-3 mt-4'>
                            <div className=''> <FcOk /> </div>
                            <div>
                                <p className='text-sm font-bold text-gray-800'>White label the Chat Page, chat link and popout chat widget</p>
                                <p className='text-sm font-md mt-2 opacity-100  text-gray-800'>with your own domain for a URL that matches your website and brand.</p>
                            </div>
                        </div>
                    </div>

                    <div className='desc mt-5'>
                        <div className='text-sm font-md mt-2 opacity-90  text-gray-800'>Billed on a Property level, it can be purchased for each Property you would like to rebrand and make your own.
                        </div>
                    </div>

                    <div className='p-8 border bg-yellow-100 mt-5 rounded-md shadow-md'>
                        <div className='text-center font-bold text-gray-800 text-sm'>
                            Tailor the visitor experience with custom branding on the chat widget, in ticketing emails and chat transcripts.
                            <div className='mt-4'>
                                <Button btnName="Activate Now" className="bg-green-500 text-white p-2 font-md" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RemoveBranding