import React, { useState } from 'react';

function SwitchBox(props) {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const statusText = isChecked ? 'Active' : 'Inactive';

    return (
        <div>
            <div className='flex gap-2'>
                <div>{props.logo}</div>
                <div className={`text-md font-bold text-gray-700 ${props.className}`}>
                    <label>{props.title}</label>
                </div>
            </div>
            <div className='border b-2 border-gray-400 flex justify-between p-4 mt-2 rounded shadow bg-white'>
                <div>
                    <label className="relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={isChecked}
                            onChange={handleChange}
                        />
                        <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}></div>
                    </label>
                </div>
                <div>{statusText}</div>
            </div>
        </div>
    );
}

export default SwitchBox;