import React from 'react'

function Button(props) {
  return (
    <div>
      <div>
        <button className={`shadow p-1 rounded border border-gray-400 ${props.className}`}>{props.btnName}</button>
      </div>
    </div>
  )
}

export default Button