import React, { useState } from 'react'
import Button from '../../ReuseableCompo/Button/Button'
import MyinputComponent from '../../Components/MyinputComponent/MyinputComponent'

function Webhooks() {
    const [isCreated, setIsisCreated] = useState(false);

    const handleChange = () => {
        setIsisCreated(!isCreated);
    };
    const hellooo = () => {
        setIsisCreated(false);
    };
    const saveHandle = () => {
        alert(" hjasjsjds");
    }
    return (
        <div>
            <div className='mt-5'>
                <div className='text-lg font-bold text-gray-700'>WEBHOOKS</div>
                <div className='flex flex-col lg:flex-row  justify-between mt-3 '>
                    <span className='text-sm opacity-75'>
                        Create a webhook and send real-time notifications of events within tawk.to to external services. Choose to send a webhook event when a chat begins, ends, when a ticket is created or all three. Then add your endpoint URL and name your new webhook, simple. To learn more about how to use webhooks with tawk.to click here..
                    </span>
                    {
                        isCreated ? null : <div onClick={handleChange}>
                            <Button btnName="+ Create Webhook" className="bg-green-400 w-60 p-2 text-white" />
                        </div>
                    }
                </div>

                {
                    isCreated ? <div className='mt-5 create_webhook'>
                        <label className='font-bold text-gray-700'>Create Webhook</label>
                        <div className='shadow border border-gray-400 p-5 mt-3'>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full'>
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full'>
                                    <MyinputComponent title="Name" type="text" />
                                    <MyinputComponent title="Your Endpoint URL" type="text" />
                                </div>
                                <div>
                                    <label>Select Events</label>
                                    <div className='flex items-center gap-3 mt-3'>

                                        <div className='flex gap-2'>
                                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                            <label htmlFor="vehicle1"> Chat Start</label>
                                        </div>
                                        <div className='flex gap-2'>
                                            <input type="checkbox" id="vehicle2" name="vehicle1" value="Bike" />
                                            <label htmlFor="vehicle2"> Chat End</label>
                                        </div>
                                        <div className='flex gap-2'>
                                            <input type="checkbox" id="vehicle3" name="vehicle1" value="Bike" />
                                            <label htmlFor="vehicle3"> New ticket</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex  justify-end gap-3'>
                                <div onClick={saveHandle}>
                                    <Button btnName="save" type="submit" className="p-2 bg-green-400 text-white" />
                                </div>
                                <div onClick={hellooo}>
                                    <Button btnName="cancel" className="p-2 bg-white-400 text-black" />
                                </div>
                            </div>
                        </div>
                    </div>
                        : null
                }
            </div>

        </div>
    )
}

export default Webhooks