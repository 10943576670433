import React from 'react'

function WidgetContent(props) {
    return (
        <div>
            <label className='text-md font-bold text-gray-700'>{props.label}</label>
            <div className={`shadow p-4 rounded border border-gray-400 mt-2 ${props.className}`}>
                <div className='flex justify-between gap-1'>
                    <div className='w-full'>
                        <div>
                            <div className='flex gap-2'>
                                <div>
                                    {props.logo}
                                </div>
                                <div className='text-md font-bold text-gray-700'>
                                    <label>{props.title}</label>
                                </div>
                            </div>

                            <select name="cars" id="cars" className='mt-2  bg-white rounded h-10 px-3 outline-none border border-gray-400 w-full'>
                                {props?.option?.map((item, i) => {
                                    return (

                                        <option key={i}>{item}</option>

                                    )
                                })

                                }
                            </select>

                        </div>
                    </div>
                    <div className='mt-2'>
                        <div>
                            <div>
                                <button className={`shadow p-1 rounded border border-gray-400 ${props.className}`}>{props.btnName}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WidgetContent