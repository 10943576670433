import React from 'react'
import KnowledgeBase from '../../images/shortcut.svg'
import Button from '../../ReuseableCompo/Button/Button'

function Shortcuts() {
    return (
        <div>
            <div className='shadow border border-gray-400 rounded h-[87vh] '>
                <div className='flex justify-center items-center flex-col h-[87vh]'>
                    <img src={KnowledgeBase}  alt="hello"/>
                    <div className='text-3xl font-bold text-gray-700 mt-8'>Set up Shortcuts</div>
                    <span className='text-sm opacity-50 mt-2'>Shortcuts are template responses you can use while chatting with visitors to save time and make sure your responses are accurate and consistent.
                    </span>
                    <div className='flex gap-5'>
                        <Button btnName="Add Shortcut" className="my-5 pl-9 pr-9 pt-2 pb-2 bg-green-500 text-white" />
                        <Button btnName="Import from CSV" className="my-5 pl-9 pr-9 pt-2 pb-2 bg-white-500 text-black" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shortcuts