import React from 'react'
import { CardData } from '../../ReuseableCompo/CardData'

function UseableCard() {
    return (
        <React.Fragment>
            <div className="px-3 my-5 flex flex-wrap justify-center gap-4">
                {CardData.map((item, index) => {
                    return (
                        <div key={index} className=''>
                            <div className="max-w-sm rounded overflow-hidden shadow-lg h-[400px] w-[320px] ">
                                <img
                                    className="w-full"
                                    src={item.image}
                                    alt="Sunset in the mountains"
                                />
                                <div className="px-6 py-4 h-[180px]">
                                    <div className="font-bold  text-gray-700 text-md mb-2">{item.title}</div>
                                    <p className="text-gray-700 text-base">
                                        {item.desc}
                                    </p>
                                </div>
                                <div className='px-6 py-4 flex justify-between'>
                                    <div className='font-bold  text-gray-700 text-sm'>{item.rate}</div>
                                    <div>
                                        <button className='bg-green-500 p-1 rounded-md text-sm text-white'>Activate Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </React.Fragment>
    )
}

export default UseableCard