import React from 'react'

function SelectBox(props) {
    return (
        <div>
            <div className='flex gap-2'>
                <div>
                    {props.logo}
                </div>
                <div className='text-md font-bold text-gray-700'>
                    <label>{props.title}</label>
                </div>
            </div>

            <select name="cars" id="cars" className='mt-2  bg-white rounded h-10 px-3 outline-none border border-gray-400 w-full'>
                {props?.option?.map((item, i) => {
                    return (

                        <option key={i}>{item}</option>

                    )
                })

                }
            </select>

        </div>
    )
}

export default SelectBox


