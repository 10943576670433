export const TabsInputData = [
    {
        id: 1,
        title: "Details",
        rate:"Free or Upgrade"
    },
    {
        id: 2,
        title: "Knowledge Base",
        rate:"$19.00 / month"

    },

    {
        id: 3,
        title: "Shortcuts",
        rate:"$1.00 / hr"


    },
    {
        id: 4,
        title: "History",
        rate:"$7.00 / hr"
    },
]
