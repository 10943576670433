import React, { useEffect, useState } from 'react';
import Button from "../../ReuseableCompo/Button/Button"

function ImageUploader(props) {
    const [selectedImage, setSelectedImage] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpHiFt8dyV6O3oRWl5Gpc8mdj6SQRu7kz2l68d70gPTGhpCa3GQpuFxrvdFVyZlSUvcjw&usqp=CAU");

    useEffect(() => {
        const savedImage = localStorage.getItem('Image');
        if (savedImage) {
            setSelectedImage(savedImage);
        }
    }, []);


    const handleSaveImage = () => {
        localStorage.setItem("Image", selectedImage)
    };

    const removeImage = () => {
        localStorage.removeItem('Image'); // Use removeItem to remove the 'Image' key
        setSelectedImage(null); // Clear the selectedImage state as well
    }
    const handleImage = () => {

        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.addEventListener("change", (event) => {
            const file = event.target.files[0];
            if (file) {
                const imageUrl = URL.createObjectURL(file);
                setSelectedImage(imageUrl);
            }
        });
        fileInput.click();
    }
    return (
        <div>

            <div className='flex gap-3'>
                <div>
                    {props.logo}
                </div>
                <div>
                    <label className='font-bold text-gray-700'>{props.label}</label>
                </div>
            </div>

            <div className='border border-gray-400 shadow rounded flex mt-2 p-2 h-[155px]'>
                <div className='p-0 w-[160px] h-[100px]'>
                    <img src={selectedImage} onClick={handleImage} alt="mydata" className='h-[100%] w-[100%] object-contain' />
                </div>
                
                <div className='flex justify-between'>
                    <div className='mt-8 ' onClick={handleSaveImage}>
                        <Button btnName="Save" />
                    </div>

                    <div className='mt-8 ml-2' onClick={removeImage}>
                        <Button btnName="Remove" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageUploader;
