import React from 'react'

function JavascriptApi(props) {
    return (
        <div>
            <div className='flex gap-2'>
                <div>
                    {props.logo}
                </div>
                <div className='text-md font-bold text-gray-700'>
                    <label>{props.label}</label>
                </div>
            </div>
            <div className='border b-2 border-gray-400  p-5 mt-2 rounded  shadow bg-white'>
                <div>Key</div>
                <input type='text' className='border b-2 border-gray-400 w-full p-2 rounded mt-2' defaultValue={props.defaultValue} disabled />
            </div>

        </div>
    )
}

export default JavascriptApi