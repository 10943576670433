import React from 'react'
import KnowledgeBase from "../../images/kb-image.svg"
import Button from '../../ReuseableCompo/Button/Button'
function Knowledgebase() {
  return (
    <div>
      <div className='shadow border border-gray-400 rounded p-5'>
        cxzcxz
      </div>

      <div className='shadow border border-gray-400 rounded p-5 mt-6 '>
        <div className='flex justify-center text-center'>
          <img src={KnowledgeBase} alt="hello" />
        </div>
        <div className='flex justify-center flex-col text-center'>
          <h2 className='mt-3 text-3xl font-bold text-gray-700'>Getting Started</h2>
          <span className='opacity-50 mt-2'>Implementing a knowledge base can have a positive effect on the quality and efficiency of your service</span>
          <Button btnName="start" className="my-5 pl-7 pr-7 bg-green-500 text-white" />
        </div>
      </div>
    </div>
  )
}

export default Knowledgebase