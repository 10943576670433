import React from 'react'

function Reuseable(props) {
    return (
        <div>
            <div className='text-md font-bold text-gray-700 mt-4'>
                <label>{props.label}</label>
            </div>

            
            <div className={`shadow border border-gray-400 rounded  ${props.className}`}>
                {props.data}
            </div>
        </div>
    )
}

export default Reuseable
