import React from 'react'
import MyinputComponent from '../../Components/MyinputComponent/MyinputComponent'
import { PropertyIDIcon, PropertyNameIcon, PropertyStatusIcon, PropertyURLIcon } from '../../ReuseableCompo/icons'
import SwitchBox from '../../ReuseableCompo/SwichBox/SwitchBox'
import Reuseable from '../../Components/ReuseableCard/Reuseable'
import Button from '../../ReuseableCompo/Button/Button'
import WidgetContent from '../../Components/WidgetContent/WidgetContent'

function Page() {
  return (
    <div>
      <div className='mt-5'>
        <div className='text-lg font-bold text-gray-700' >PAGE</div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full mt-3'>
          <div>
            <MyinputComponent title="Page Name" type="text" label={PropertyNameIcon} />
            <div className='mt-5'>
              <SwitchBox title="Page Status" data="Inactive" label={PropertyStatusIcon} />
            </div>
          </div>
          <MyinputComponent title="Page Image" type="text" className="h-[155px]" label={PropertyIDIcon} />
        </div>
        <div className='mt-5 grid grid-cols-1 lg:grid-cols-2 gap-5 w-full'>
          <MyinputComponent title="Visitor page URL" type="text" label={PropertyURLIcon} />
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full'>
          <div>
            <Reuseable className="mt-2 p-9" data={
              <div className='flex justify-between'>
                <div>
                  <input type="color" className='h-10 w-15' />
                </div>
                <div>
                  <Button btnName="Advanced" />
                </div>
              </div>
            } label="Page Appearance" />
          </div>
          <div className='mt-2'>
            <label className='text-md font-bold text-gray-700'>Page Content</label>
            <WidgetContent title="Language" btnName="EditContent" className="mt-6" option={['English', 'Hindi', 'Urdu', 'Tamil']} />
          </div>

          <div>
            <label className='text-md font-bold text-gray-700'> Chat Widget</label>
            <WidgetContent title="Language" btnName="EditContent" className="mt-6" option={['English', 'Hindi', 'Urdu', 'Tamil']} />
          </div>
        </div>
        <div className='mt-3 opacity-50'>
          <p>
            To customize the widget appearance, content, and behavior, please customize the selected widget from within the Chat Widgets section of the dashboard.
          </p>
        </div>

        <div className='mt-3  rounded-[5px]'>
          <Button className="bg-red-500 p-2 text-white" btnName="Delete Widget" />
        </div>
      </div>
    </div>
  )
}

export default Page