import React from 'react'
import UseableCard from '../AddOns/UseableCard'

function AddOns() {
  return (
    <React.Fragment>

      <div className='mt-1 shadow border border-gray-400 rounded h-[87vh] overflow-y-scroll'>
        <div className='sticky top-0 bg-white'>
          <div className='text-lg font-bold text-gray-700 p-3'>Add-ons</div>
          <hr className='border border-gray-300' />
        </div>
        <div className='flex justify-center'>
          <UseableCard />
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddOns