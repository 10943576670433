import React from 'react'

function MyinputComponent(props) {
  return (
    <div>
      <label className='flex gap-3 tetx-gray-600 text-base font-medium'>
        <div>{props.label}</div>
        <div className='text-md font-bold text-gray-700'>{props.title}</div>
      </label>
      <input type={props.type} defaultValue={props.defaultValue} className={`mt-2 rounded h-10 px-3 outline-none border border-gray-400 w-full ${props.className}`}
      />
    </div>
  )
}

export default MyinputComponent
