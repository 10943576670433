import React from 'react'
import { DatabaseIcon, PersonIcon, RightarrowIcon, barsIcon } from '../ReuseableCompo/icons'

function Topicon() {
  return (
    <div className='p-5 flex justify-between '>
      <div className='text-gray-500'>{DatabaseIcon}</div>
      <div className='text-gray-500'>{PersonIcon}</div>
      <div className='text-gray-500'>{barsIcon}</div>
      <div className='text-gray-500'>{RightarrowIcon}</div>

    </div>
  )
}

export default Topicon