export const LeftLinks =[
    {
        id:1,
        title:"Groups",
        child:[],
    },
    {
        id:2,
        title:"Direct Message (1)",
        child:[
          {  "title":"Faizan"},
        ],
    },

]
