import React, { useState } from "react";
import { SidebarLink } from "./SidebarLinks";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";
import Rightblack from "../images/Rightblack.png";
import Downblack from "../images/Downblack.png";
import { NavLink } from "react-router-dom";
import { LeftarrowIcon, RightarrowIcon,pinIcon } from "../ReuseableCompo/icons";

function Sidebar() {
  const [isChecked, setIsChecked] = useState(false);

  const helloToggle = () => {
    setIsChecked(!isChecked);
  };


  const [open, setOpen] = React.useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <div className="bg-white border b-2 border-gray-400 rounded  px-3  p-3 h-[87vh] fixed top-[70px] z-[100]">
      <div className="flex items-center justify-between">
        <div  className={isChecked ? 'hidden text-black font-normal': 'lg:block text-black font-normal'}>Administration</div>
        <div className="text xs text-green-500" onClick={helloToggle}>{isChecked ? RightarrowIcon : LeftarrowIcon }</div>
      </div>

      <NavLink to={"/overview"}>
        <div className="mt-2 rounded p-2 bg-green-100 text-sm flex px-6 gap-2 text-green-700">
          <div>{pinIcon}</div> <div className={isChecked ? 'hidden': 'lg:block'}>Overview</div>{" "}
        </div>
      </NavLink>
      <div className="mt-2 ">
        {SidebarLink.map((item, index) => {
          return (
            <div key={index}>
              <Accordion open={open === item.id}>

                <AccordionHeader
                  onClick={() => handleOpen(item.id)}
                  className="border-0 text-xs bg-white hover:bg-gray-300 my-1 rounded py-3 px-2"
                >
                  <div className="flex items-center gap-x-2 justify-center ">
                    <img
                      src={open === item.id ? Downblack : Rightblack}
                      alt=""
                      className="w-2 mt-1"
                    />
                    <div>{item.icon}</div>
                    <div className={isChecked ? 'hidden': 'lg:block'}>{item.title}</div>
                  </div>
                </AccordionHeader>

                <AccordionBody className=" p-2 mt-1">
                  <div>
                    {item.child.map((e, i) => {
                      return (
                        <NavLink to={e.url} className="flex" key={i}>
                          <div className=" rounded p-2bg-white hover:bg-gray-300 w-full py-3 text-sm flex px-4 gap-2 ">
                            <div>{e.icon}</div> <div className={isChecked ? 'hidden': 'lg:block'}>{e.title}</div>{" "}
                          </div>
                        </NavLink>
                      );
                    })}
                  </div>
                </AccordionBody>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Sidebar;
