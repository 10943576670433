import React from 'react'
import MyinputComponent from '../../Components/MyinputComponent/MyinputComponent'
import SelectBox from '../../ReuseableCompo/SelectBox/SelectBox'
import Button from '../../ReuseableCompo/Button/Button'
import { PropertyNameIcon, PropertyURLIcon } from '../../ReuseableCompo/icons'
import SwitchBox from '../../ReuseableCompo/SwichBox/SwitchBox'
import Reuseable from '../../Components/ReuseableCard/Reuseable'
import WidgetContent from '../../Components/WidgetContent/WidgetContent'
import { useState } from 'react';
import copy from 'clipboard-copy';
import SwitchBtn from '../../ReuseableCompo/Button/SwitchBtn'
function ChatWidget() {
  const [textToCopy, setTextToCopy] = useState('Text to copy');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await copy(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div >
      <h2 className='text-lg font-bold text-gray-700 mt-5'>CHAT WIDGET</h2>
      <div className='grid grid-cols-10 gap-5 w-full'>
        <div className='col-span-9'>
          <SelectBox option={['vovo', 'bogo', 'tobo', 'lolo']} />
        </div>
        <div className='col-span-1 mt-3'>
          <Button className="bg-green-700 text-white pl-4 pr-4 pt-1 pb-2" btnName="Add" /></div>
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full mt-5'>
        <div>
          <MyinputComponent title="Widget Name" type="text" label={PropertyNameIcon} />
          <SwitchBox className=" mt-3" title="Widget Status" data="Disabled" />
          <Reuseable className="mt-2 p-3" data="1h8ea7mp4" label="Widget ID" />
        </div>

        <div>
          <input type="text" className='rounded  px-3 outline-none border border-gray-400 w-full "bg-white h-[250px] mt-7  hover:bg-green-400 hover:text-white' value={textToCopy} onChange={(e) => setTextToCopy(e.target.value)} />

          <div className='text-end'>
            <button className='shadow p-1 mt-2 rounded border border-gray-400' onClick={handleCopyClick}>Copy to Clipboard !</button>
            {isCopied && <span>Copied!</span>}
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 gap-5 w-full'>
        <Reuseable className=" grid grid-cols-1 gap-5 w-full mt-2 p-3" data="https://tawk.to/chat/64e4840ecc26a871b030a888/1h8ea7mp4" label="Direct Chat Link" title={PropertyURLIcon} />
      </div>

      <div className='grid grid-cols-1 gap-5 w-fulll'>
        <div>
          <Reuseable className="mt-2 p-9" data={
            <div className='flex justify-between'>
              <div>
                <input type="color" className='h-10 w-15' />
              </div>
              <div>
                <Button btnName="Advanced" />
              </div>
            </div>
          } label="Widget Appearance" />
        </div>
        <div className='mt-6'>
          <WidgetContent title="Language" btnName="EditContent" className="mt-6" option={['English', 'Hindi', 'Urdu', 'Tamil']} />
        </div>
        <WidgetContent label="Scheduler" title="Timezone" btnName="EditTimezone" className="mt-6 p-1.5" option={['Colombo, Sri Lanka UTC +05:30', 'Abidjan, Ivory Coast UTC +00:00', 'Accra, Togo UTC +00:00', 'Asmara, Eritrea UTC +03:00']} />
        <WidgetContent label="Schedule options" title="Schedule" btnName="Advanced" className="mt-6 p-1.5" option={['Custom', 'Disabled', 'Mon-Sun(9am-6pm)', 'Sat-Sun(9am-6pm)']} />
      </div>

      <div className='mt-5 grid grid-cols-1 gap-5 w-full'>
        <label className='text-md font-bold text-gray-700'>Widget Behavior</label>
        <div className='border border-gray-400 mt-3 rounded shadow p-6'>
          <span className='text-sm text-gray'>Notification Settings</span>
          <div className='flex justify-between mt-3'>
            <div>
              <ul>
                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div> <li>Hide estimated wait time</li></div>
                </div>

                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div><li>Disable sound notification</li></div>
                </div>

                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div><li>Disable message preview on desktop</li></div>
                </div>

                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div><li>Disable message preview on mobile</li></div>
                </div>
              </ul>
            </div>
            <div>
              <ul>
                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div><li>Hide estimated wait time</li></div>
                </div>
                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div><li>Disable sound notification</li></div>
                </div>
                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div><li>Disable message preview on desktop</li></div>
                </div>
                <div className='flex gap-2'>
                  <div> <SwitchBtn /> </div>
                  <div><li>Disable message preview on mobile</li></div>
                </div>

              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 gap-5 w-full'>
        <div className='mt-5'>
          <div className='border border-gray-400 mt-3 rounded shadow p-6'>
            <span className='text-sm text-gray'>Visibility Settings</span>
            <div className=' mt-3'>
              <div>
                <ul>
                  <div className='flex gap-2'>
                    <div> <SwitchBtn /> </div>
                    <div> <li>Hide widget when offline</li></div>
                  </div>
                  <div className='flex gap-2'>
                    <div> <SwitchBtn /> </div>
                    <div> <li>Hide widget on load in desktop</li></div>
                  </div>
                  <div className='flex gap-2'>
                    <div> <SwitchBtn /> </div>
                    <div> <li>Hide widget on load in mobile</li></div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <div className='border border-gray-400 mt-3 rounded shadow p-6'>
            <span className='text-sm text-gray'>Feature Settings</span>
            <div className=' mt-3'>
              <div>
                <ul>
                  <div className='flex gap-2'>
                    <div> <SwitchBtn /> </div>
                    <div> <li>Disable emoji selection</li></div>
                  </div>
                  <div className='flex gap-2'>
                    <div> <SwitchBtn /> </div>
                    <div> <li>Disable file upload</li></div>
                  </div>
                  <div className='flex gap-2'>
                    <div> <SwitchBtn /> </div>
                    <div> <li>Disable chat rating</li></div>
                  </div>
                  <div className='flex gap-2'>
                    <div> <SwitchBtn /> </div>
                    <div> <li>Disable email transcript</li></div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5 grid grid-cols-1 gap-5 w-full'>
        <div className='border border-gray-400 mt-3 rounded shadow p-6'>
          <span className='text-sm text-gray'>On Click Behavior</span>
          <div>
            <SelectBox option={['Maximize', 'Popout']} />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 gap-5 w-full'>
        <div className='mt-5'>
          <div className='border border-gray-400 mt-3 rounded shadow p-6'>
            <span className='text-sm text-gray'>Domain Restriction</span>
            <div className='flex justify-between mt-3'>
              <div>
                <span>
                  Disabled, Configure to Enable
                </span>
              </div>
              <div>
                <Button btnName="Configure" />
              </div>
            </div>
            <div className='mt-3'>
              <p>By default, the code will work on all the domains and URLs where it has been inserted. To show or hide the widget on one or more specific domain or URLs, enable this functionality and specify the rule.</p>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <div className='border border-gray-400 mt-3 rounded shadow p-6'>
            <span className='text-sm text-gray'>Country Restriction</span>
            <div className='flex justify-between mt-3'>
              <div>
                <span>
                  Disabled, Configure to Enable
                </span>
              </div>
              <div>
                <Button btnName="Configure" />
              </div>
            </div>
            <div className='mt-3'>
              <p>By default, the widget will be shown to all visitors. To show or hide the widget for visitors from specific countries, enable this functionality and specify the countries.</p>
            </div>
          </div>
        </div>

        <div className='mt-5'>
          <div className='border border-gray-400 mt-3 rounded shadow p-6'>
            <span className='text-sm text-gray'>Platform Restriction</span>
            <div className='flex justify-between mt-3'>
              <div>
                <span>
                  Disabled, Configure to Enable
                </span>
              </div>
              <div>
                <Button btnName="Configure" />
              </div>
            </div>
            <div className='mt-3'>
              <p>By default, the widget will be shown to all visitors. To show or hide the widget for visitors from specific platforms, enable this functionality and specify the platforms.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-3'>
        <Button btnName="Delete Widget" className="bg-red-500 text-white p-2 rounded-[5px]" />
      </div>
    </div>
  )
}

export default ChatWidget