import React from 'react'
import MyinputComponent from '../../Components/MyinputComponent/MyinputComponent'
import { DatabaseIcon, JavaScriptApiIcon, PropertyIDIcon, PropertyImageIcon, PropertyNameIcon, PropertyRegionIcon, PropertyStatusIcon, PropertyURLIcon } from '../../ReuseableCompo/icons'
import SwitchBox from '../../ReuseableCompo/SwichBox/SwitchBox'
import SelectBox from '../../ReuseableCompo/SelectBox/SelectBox'
import DiscoverSetting from '../../Components/DiscoverSetting/DiscoverSetting'
import Button from '../../ReuseableCompo/Button/Button'
import JavascriptApi from '../../Components/JavascriptAPI/JavascriptApi'
import ImageUploader from '../../Components/ImageUpload/ImageUploader'

function Overview() {
  return (
    <div className='mt-5' >
      <div className='text-lg font-bold text-gray-700'>OVERVIEW</div>
      <div className='mt-5'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full'>
          <div>
            {/* <div className='text-end'> <Button btnName="save" /> </div> */}
            <MyinputComponent title="Property Name" type="text" label={PropertyNameIcon} defaultValue="https://test.layoutsd" />
            <div className='mt-5'> <SwitchBox title="Property Status" logo={PropertyStatusIcon} data="Inactive" /> </div>
          </div>
          <div> <ImageUploader label="Property Image" logo={PropertyImageIcon} /> </div>
          <MyinputComponent title="Property URL" type="text" label={PropertyURLIcon} defaultValue="https://test.layoutsdot.com/uniChat/" />
          <MyinputComponent title="Property ID" type="text" label={PropertyIDIcon} defaultValue="64e4840ecc26a871b030a888" />
          <SelectBox title="Property Region" option={['tab', 'akash', 'aman', 'xoxo']} logo={PropertyRegionIcon} />
          <MyinputComponent title="Ticket Forwarding Email" type="email" defaultValue="tickets@test-layoutsdot-com.p.tawk.email" />
          <DiscoverSetting />

          <div className='bg-white p-3 mt-8 flex flex-col gap-y-5 shadow border  border-gray-400 rounded'>
            <SelectBox title="Category" option={['vovo', 'bogo', 'tobo', 'lolo']} />
            <SelectBox title="Subcategory" option={['vddd', 'ddgo', 'tssbo', 'loddo']} />
            <div> <div className='text-right'> <Button btnName="save" /> </div> </div>
          </div>

          <div className='bg-white p-3 mt-5 flex flex-col gap-y-5 shadow border border-gray-400 rounded'>
            <label className='text-md font-bold text-gray-700'>Description</label>
            <textarea className='border border-gray-400 rounded' id="w3review" name="w3review" rows="4" cols="50"> </textarea>
            <div> <div className='text-right'> <Button btnName="save" /> </div> </div>
          </div>

          <div className='bg-white p-3 mt-5 flex flex-col gap-y-5 shadow border border-gray-400 rounded'>
            <label className='text-md font-bold text-gray-700' >Keyterms</label>
            <textarea className='border border-gray-400 rounded' id="w3review" name="w3review" rows="4" cols="50"> </textarea>
            <div> <div className='text-right'> <Button btnName="save" /> </div> </div>
          </div>

          <JavascriptApi label="Javascript API (Key)" logo={JavaScriptApiIcon} defaultValue="d52d8234a692102be709b3718fee374c0e616c25" />
          <SwitchBox title="Source Mode" data="Disabled" />
          <SwitchBox title="Data & Privacy" logo={DatabaseIcon} data="Disabled" />
        </div>

        <div>
          <Button btnName="Delete Property" className='mt-3 bg-red-600 text-white pl-5 pr-5 pt-2 pb-2 rounded-[6px]' />
        </div>

      </div>
    </div>
  )
}

export default Overview
