import React from 'react'
import DashboardLeftBar from './DashboardLeftBar'

function Dashboard() {
  return (
    <div className="fixed w-[14.3%]">
        <DashboardLeftBar/>
    </div>
  )
}

export default Dashboard
