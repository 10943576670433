import React from 'react'
import { AddOnsIcon, ConatactIcon, DashboardIcon, InboxIcon, KnowledgeBaseIcon, LinesIcon, MessageIcon, ReportIcon, SearchIcon, SettingsIcon } from '../ReuseableCompo/icons'

function Header() {
  return (
    <div className='grid grid-cols-6 gap-x-[1px]  fixed' style={{ width: "calc(100% - 14.3%)", zIndex: "10" }}>
      <div className="bg-gray-900 text-white font-medium p-4 col-span-1">
        https://test//layout...
      </div>
      <div className="bg-gray-900 text-white font-medium p-4 col-span-5">
        <div className="flex justify-between">
          <div className="flex gap-x-5">
            <div>{DashboardIcon}</div>
            <div>{InboxIcon}</div>
            <div>{ConatactIcon}</div>
            <div>{KnowledgeBaseIcon}</div>
            <div>{ReportIcon}</div>
            <div>{AddOnsIcon}</div>
            <div>{SettingsIcon}</div>
          </div>
          <div className="flex gap-x-5 pr-5">
            <div>{LinesIcon}</div>
            <div>{MessageIcon}</div>
            <div>{SearchIcon}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header