import React from 'react'
import RemoveBranding from './RemoveBranding'

function HireVirtualAs() {
  return (
    <div>
        <RemoveBranding title="Video + Voice + Screensharing" rate="$29.00 / month" subtitle="Solve problems fast for your customers with Video Chat, Voice Calls and 2-way screen sharing."/>
    </div>
  )
}

export default HireVirtualAs