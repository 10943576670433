import { AddOnsIcon, AgentAlertsIcon, AiAsstIcon, AutotranslateIcon, BanListIcon, BillingIcon, CartIcon, ChannelsIcon, ChatWidgetIcon, ContactAttrIcon,  DepartmentsIcon, HireLiveAgentIcon, HireVirtualAsstIcon, KnowledgeBaseIcon, MailNotificationsIcon, PageIcon, PayemetIcon, PersonIcon, PhoneNoIcon, PropertyURLIcon, ProprtyMembersIcon, RemoveBrandingIcon, SettingsIcon, ShortcutsIcon, TabsIcon, VDSIcon } from "../ReuseableCompo/icons";

export const SidebarLink = [
    {
        id: 1,
        title: "Channels",
        icon: ChannelsIcon,
        child: [
            { "title": "Chat widget", "icon": ChatWidgetIcon, url: "/channels/chat-widget" },
            { "title": "Page", "icon": PageIcon, url: '/channels/page' },
            { "title": "Knowledge Base", "icon": KnowledgeBaseIcon, url: "/channels/knowledge-base" },
        ]
    },
    {
        id: 2,
        title: "User Managment",
        icon: PersonIcon,
        child: [
            { "title": "Property Members", "icon": ProprtyMembersIcon, url: "/users/members" },
            { "title": "Departments", "icon": DepartmentsIcon, url: "/users/departments" },
            { "title": "Agent Alerts", "icon": AgentAlertsIcon, url: "/users/alerts" },
            { "title": "Ban List", "icon": BanListIcon, url: "/users/ban-list" },
        ]
    },
    {
        id: 3,
        title: "Settings",
        icon: SettingsIcon,
        child: [
            { "title": "Shortcuts", "icon": ShortcutsIcon, url: "/settings/shortcuts" },
            { "title": "Triggers", "icon": SettingsIcon, url: "/settings/triggers" },
            { "title": "Tabs", "icon": TabsIcon, url: "/settings/tabs" },
            { "title": "Mail Notification", "icon": MailNotificationsIcon, url: "/settings/mail" },
            { "title": "Webhooks", "icon": PropertyURLIcon, url: "/settings/integrations" },
            { "title": "Conatct Attributes", "icon": ContactAttrIcon, url: "/settings/contact-attributes" },
        ]
    },
    {
        id: 4,
        title: "Add-ons",
        icon: AddOnsIcon,
        url:"/addon-store",
        child: [
            { "title": "AI Assist", "icon": AiAsstIcon, url: "/addon-store/ai-assistant" },
            { "title": "Remove Branding", "icon": RemoveBrandingIcon, url: "/addon-store/whitelabel" },
            { "title": "Hire Live Agents", "icon": HireLiveAgentIcon, url: "/addon-store/live-answering" },
            { "title": "Hire Virtual Assistants", "icon": HireVirtualAsstIcon, url: "/addon-store/virtual-assistant" },
            { "title": "Video + Voice + Screensharing", "icon": VDSIcon, url: "/addon-store/webrtc" },
            { "title": "Activate Phone Number", "icon": PhoneNoIcon, url: "/chat-widget" },
            { "title": "Auto Translation", "icon": AutotranslateIcon, url: "/chat-widget" },
            { "title": "Shopping Cart", "icon": CartIcon, url: "/chat-widget" },
            { "title": "In-Chat Payments", "icon": PayemetIcon, url: "/chat-widget" },
        ]
    },
    {
        id: 5,
        title: "Billing",
        icon: BillingIcon,
        child: [
            { "title": "Purchases and Invoices", "icon": SettingsIcon, url: "/billing/purchases" },

        ]
    },
]

