import React from 'react'
import { TabsInputData } from '../Settings/TabsData'
import { RiDeleteBinLine } from 'react-icons/ri'
import SwitchBtn from '../../ReuseableCompo/Button/SwitchBtn'
import { ClockIcon, KnowledgeBaseIcon, PersonIcon, ShortcutsIcon } from '../../ReuseableCompo/icons'
import Button from '../../ReuseableCompo/Button/Button'

function UseableCard() {
  
    return (
        <React.Fragment>
            <div className='mt-5'>
                <div className='text-lg font-bold text-gray-700'>TABS</div>
                <div className='flex flex-col lg:flex-row  justify-between mt-3 '>
                    <span className='text-sm opacity-75'>
                        Create custom tabs in the details pane of chats and messages.
                    </span>
                    <div>
                        <Button btnName="+Add Tab" className="bg-green-500 text-white p-2" />
                    </div>
                </div>
            </div>
            <div className='mt-8 grid grid-cols-1 lg:grid-cols-2 gap-5 w-full' >
                <div>
                    <div className='text-lg font-bold text-gray-700'> My Tabs</div>
                    {TabsInputData.map((item, index) => {
                        return (
                            <div className='mt-3'>

                                <div className='p-3 shadow border border-gray-400 rounded'>
                                    <div className="flex justify-between group">
                                        <div>{item.title}</div>
                                        <div className="hidden group-hover:block">
                                            <div className='flex gap-2'>
                                                <RiDeleteBinLine style={{ color: "red", fontSize: "20px" }} />
                                                <SwitchBtn />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <div className='h-[650px] shadow border border-gray-400 rounded mt-10'>
                    <div className='p-3 gap-8 flex '>
                        <div>{PersonIcon}</div>
                        <div>{KnowledgeBaseIcon}</div>
                        <div>{ShortcutsIcon}</div>
                        <div>{ClockIcon}</div>
                    </div>
                    <hr className='border border-gray-400' />
                    <div className='h-btn'>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UseableCard