import React from 'react'
import MyinputComponent from '../../Components/MyinputComponent/MyinputComponent'
import { MailNotificationsIcon, PropertyImageIcon, PropertyStatusIcon } from '../../ReuseableCompo/icons'
import SelectBox from '../../ReuseableCompo/SelectBox/SelectBox'


function Mailnotification() {
    return (
        <div>
            <div className='mt-5'>
                <div className='text-lg font-bold text-gray-700'>MAIL NOTIFICATIONS</div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <SelectBox title="Send all Chat Transcripts to" option={['Nobody', 'All', 'Only Admins', 'Department selected in prechat form']} logo={MailNotificationsIcon} />
                </div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <MyinputComponent title="And other Agents or any email address" type="text" />
                </div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <SelectBox title="Send New Ticket notifications to" option={['All', 'Nobody', 'Only Admins', 'Department selected in prechat form']} logo={PropertyImageIcon} />
                </div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <MyinputComponent title="Select an Agent" type="text" />
                </div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <SelectBox title="Send Offline Form notifications to" option={['All', 'Nobody', 'Only Admins', 'Department selected in prechat form']} logo={PropertyStatusIcon} />
                </div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <MyinputComponent title="And other Agents or any email address" type="text" />
                </div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <SelectBox title="Send Missed Chat notifications to" option={['All', 'Nobody', 'Only Admins', 'Department selected in prechat form']} logo={MailNotificationsIcon} />
                </div>
                <div className='mt-5 grid grid-cols-2 lg:grid-cols-1 gap-5 w-full'>
                    <MyinputComponent title="And other Agents or any email address" type="text" />
                </div>
            </div>
        </div>
    )
}

export default Mailnotification